#popupLink {
  color: #007bff;
  cursor: pointer;
}
#popupLink:hover {
  color: #007bff;
  text-decoration: underline;
}

.middleFramePopup > .popup-overlay > .popup-content {
  width: 80%;
  height: 80%;
}

#popupModal {
  font-weight: normal;
  font-family: Arial;
  color: black;
  font-size: 15px;
  height: 100%;
  width: 100%;
  margin: auto;
  white-space: normal;
  overflow: scroll;
  overflow-x: hidden;
  text-align: left;
}

/* #toggleTermBtn{
  background-color: rgb(70, 130, 180);
  color: white;
  border:0;
  border-radius: 6px;
  text-align: center;
} */

#popupModal > #closeForModal {
  background-color: white;
  right: -15px;
  top: -15px;
  text-shadow: none;
}

#toggleTermBtn {
  background-color: rgb(70, 130, 180);
  padding: 5px 15px;
  font-size: 15px;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px #999;
}

#toggleTermBtn:hover {
  background-color: rgb(22, 98, 160);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  box-shadow: 0 4px rgb(110, 104, 104);
}

#toggleTermBtn:active {
  color: #ffffff;
  background-color: rgb(70, 130, 180);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 30px 0 rgba(0, 0, 0, 0.19);
  transform: translateY(4px);
}

#popupModal > .header > .termType {
  font-size: 25px;
  color: rgb(70, 130, 180);
}

#popupModal > .header {
  font-size: 35px;
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

#popupModal > .content {
  width: 100%;
  padding: 20px 20px;
}

/* #popupModal >.content> p {
  margin-left: 10px;
  margin-right:10px;
} */

#popupModal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
#popupModal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  /*right: -10px;*/
  /*top: -10px;*/
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
