.Register {
  margin: 100px 0;
}

.Topic {
  font-size: 2.3em;
}

.Error {
  font-size: 1em;
}
