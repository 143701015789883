.wrapper {
  padding: 0 25px 0 25px;
}

.instructorHeading {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 128.91%;
  /* or 45px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #1277b8;
}

.instructorContainer {
  width: 240px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background: rgba(196, 196, 196, 0.17);
  border-radius: 10px;
}

.instructorImg {
  width: 240px;
  height: 220px;
  border-radius: 10px;
}

.instructorInfoContainer {
  width: 230px;
  height: 97px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instructorName {
  margin: 0;

  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 128.91%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  letter-spacing: 0.02em;

  color: #000000;

  border-radius: 10px;
}

.instructorLocation {
  width: 238px;
  height: 29px;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
.instructorLocation > span {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 128.91%;
  letter-spacing: 0.02em;
}
.instructorLocation > span:last-child {
  margin-left: 3px;
}

.instructorBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 27px;
}

.instructorButton {
  width: 137px;
  height: 35px;

  background: #1277b8;
  border: 2px solid #1277b8;
  box-sizing: border-box;
  border-radius: 100px;

  font-family: Roboto;
  font-size: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140.62%;
  letter-spacing: 0.01em;

  color: #ffffff;
}

.seeAllInstructors {
  color: #4a443f;
}
.seeAllInstructors:link,
.seeAllInstructors:visited,
.seeAllInstructors:hover,
.seeAllInstructors:active {
  color: #4a443f;
  text-decoration: none;
  cursor: pointer;
}

.seeAllInstructors i {
  margin-left: 10px;
}
