.ratingStarContainer {
  display: grid;
  grid-template-areas: "a a a a b b b b";
  align-items: center;
  margin-bottom: 1rem;
}

.ratingStarContainer p {
  margin-bottom: 0px;
  font-family: Roboto;
  font-weight: normal;
  color: #1277b8;
  line-height: normal;
}

.ratingStars {
  grid-column-start: 1;
  grid-row-start: 1;
  color: #ff6161;
  padding: 0.3rem;
}
