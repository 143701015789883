@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
/* usage: font-family: 'Roboto', sans-serif;*/
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900");
/* usage: font-family: 'Playfair Display', serif; */
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700");
/* usage: font-family: 'Open Sans Condensed', sans-serif;*/
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "PT Sans Narrow", sans-serif;
}

#carouselExampleIndicators {
  font-family: "Archivo Black", sans-serif;
}

/* hover style just for information */
label:hover:before {
  border: 2px solid #4778d9;
}

/* image slider css */
.carousel-inner {
  width: 100%;
  max-height: 650px;
}

#blur-image:before {
  background: rgba(0, 0, 0, 1);
}

/* password input */

[type="password"] + label:before {
  content: "121";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
