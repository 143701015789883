.containerHostCard {
  display: grid;
  grid-template-areas: "a b";
  align-items: start;
  padding: 0px;
  align-items: center;
}

.userPrice {
  color: #1277b8;
  grid-column-start: 2;
  grid-row-start: 1;
  justify-self: right;
  font-weight: bold;
}

.userPrice span {
  color: #1277b8;
  font-weight: normal;
}

.cardAction {
  justify-content: center;
  flex-direction: column;
}

.cardAction button {
  padding: 0.5rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: white;
  background: #1277b8;
  border: 2px solid #1277b8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: normal;
}

.cardAction button:hover {
  color: #1277b8;
  background: white;
}

.cardAction span {
  color: white;
  font-weight: normal;
}

.cardAction span:hover {
  color: #1277b8;
}
