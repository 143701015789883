.part2 {
  position: relative;
  margin-top: 50px;
}

.btn-root {
  font-family: "PT SANS NARROW";
  font-size: "16px";
  text-transform: "none";
  margin-left: "350px";
  width: "110px"
}

.myAccountWrap {
  padding: 100px 34.7% ;
  position: relative;
  width: 100%;
  font-family: "PT SANS NARROW";
}

.title {
  font-family: "PT Sans Narrow";
  font-weight: 400;
  font-size: 26px;
  margin-bottom: 30px;
}

.label {
  font-family: "PT SANS NARROW";
  font-size: 18px;
  line-height: 25px;
  font-weight: 8200;
  margin-top: 20px;
}

.textField {
  border: 1px black solid;
  border-radius: 10px;
  padding-left: 10px;
  width: 458px;
  height: 40px;
}


.dob {
  width: 222px;
  height: 40px;
  border: 1px black solid;
  border-radius: 10px;
  padding-left: 10px;
}

.phone {
  border: 1px black solid;
}

hr {
  margin-bottom: 0;
  width: 458px;
}

.error {
  color: #FF6161;
  margin: 20px;
}

.success {
  color: #238335;
  margin: 20px;
}