.color-slider-bar {
  display: block;
  float: left;
  -webkit-appearance: none;
  background: #969696;
  background: -moz-linear-gradient(
    left,
    #f0fd4e 5%,
    #8cf85a 20%,
    #29af1d 36%,
    #5cc2fc 52%,
    #1177b8 67%,
    #969696 84%,
    #212121 100%
  );
  background: -webkit-linear-gradient(
    left,
    #f0fd4e 5%,
    #8cf85a 20%,
    #29af1d 36%,
    #5cc2fc 52%,
    #1177b8 67%,
    #969696 84%,
    #212121 100%
  );
  background: linear-gradient(
    to right,
    #f0fd4e 5%,
    #8cf85a 20%,
    #29af1d 36%,
    #5cc2fc 52%,
    #1177b8 67%,
    #969696 84%,
    #212121 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0fd4e', endColorstr='#212121', GradientType=1);
  width: 100%;
  height: 20px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
}

input[type="range"]::-webkit-slider-thumb {
  border: 2px solid #b1adac;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.11s;
}

input[type="range"]::-webkit-slider-thumb:hover {
  border: 2px solid #8d8887;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
