footer {
  font-size: 18px;
  /* border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; */
  /* background-color: blue; */
  /* margin-top: 2rem; */
  /* position: absolute;   */
  width: "100%";
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.05);
  /* padding: 1rem 0rem; */
}

.footer-copyright {
  color: black;
  font-size: 15px;
}

.terms {
  color: #686369;
  text-decoration: underline;
  left: 0px;
  width: 111px;
}

.privacy {
  color: #686369;
  text-decoration: underline;
  left: 279px;
  width: 160px;
}

.abcd {
  font-size: 200px !important;
  display: block;
  color: #e67e22;
  margin-bottom: 10px;
}
