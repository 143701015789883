.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", sans-serif;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  border: 1px solid #cecfcf;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeInput {
  border: 1px solid #cecfcf;
  margin: 12px 0 20px 0;
  padding: 6px 14px;
  border-radius: 4px;
  background: white;
  outline: 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.Alignment {
  text-align: right;
}
