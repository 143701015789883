.chatBtn {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  background: cornflowerblue;
  position: fixed;
  bottom: 20px;
  right: 10px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji ";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
